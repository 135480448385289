// src/pages/Login.js
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../components/AuthContext";
import { setSessionToken } from '../utils/Auth';
import Notification from "../components/Notification";
import "../styles/Login.css";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { setIsLoggedIn } = useContext(AuthContext);
    const [error, setError] = useState(null);

    // Check if the user is already logged in
    useEffect(() => {
        if (AuthContext.isLoggedIn) {
            navigate('/');
        }
    }, [navigate]);

    const handleLogin = (e) => {
        e.preventDefault();
        console.log("Logging in with", { email, password });
        fetch('https://api.wanteed-job.com/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    email: email,
                    password: password
                }
            )
        })
            .then(async response => {
                if (!response.ok) {
                    setError("Invalid credentials");
                    return;
                }
                let responseJson = await response.json();
                console.log(responseJson);
                console.log("Login successful");
                setIsLoggedIn(true);
                setSessionToken(responseJson); // Store the token in cookies
                navigate('/jobs');
            }
            )
    };

    return (
        <div className="loginCard">
            <h1>Login</h1>
            <p>Welcome to Wanteed!</p>
            <form onSubmit={handleLogin} className="loginForm">
                <div className="inputRow">
                    <label htmlFor="email" className="label">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        className="inputGroup"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    /><br />
                </div>
                <div className="inputRow">
                    <label htmlFor="password" className="label">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        className="inputGroup"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    /><br />
                </div>
                <button type="submit">Log in</button>
            </form>
            {error && <Notification message={error} type="error" onClose={() => setError(null)} />}
            <p>Don't have an account? <a href="/register">Register</a></p>
        </div>
    );
}

export default Login;
