import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Button.css';

function Button({ text, link, variant = "primary", onClick, className = '' }) {
    return onClick ? (
        <button className={`button ${variant} ${className}`} onClick={onClick}>
            {text}
        </button>
    ) : (
        <Link to={link} className={`button ${variant} ${className}`}>
            {text}
        </Link>
    );
}

export default Button;
