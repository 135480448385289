// src/HomePage.js
import React from 'react';
import '../styles/HomePage.css';
import EnterpriseHome from '../components/EnterpriseHome';
import Footer from '../components/Footer';

function HomePageEnterprise() {
  return (
    <div className='home-page'>
      <EnterpriseHome />
      <Footer />
    </div >
  );
}

export default HomePageEnterprise;
