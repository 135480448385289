// src/pages/Register.js
import React, { useState } from "react";
import "../styles/Register.css";
import Notification from "../components/Notification";
import { useNavigate } from "react-router-dom";
import { setSessionToken } from "../utils/Auth";

function Register() {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [notification, setNotification] = useState(null);

    const navigate = useNavigate();

    const handleRegister = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }
        // Placeholder for the API call
        console.log("Registering with", { email, password });

        fetch('https://api.wanteed-job.com/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email, password: password, first_name: firstName, last_name: lastName }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    setNotification({ type: 'error', message: data.error });
                } else {
                    setNotification({ type: 'suc²cess', message: 'Account created successfully!' });
                    setSessionToken(data);
                    navigate('/jobs');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setNotification({ type: 'error', message: 'An error occurred.' });
            });
    };

    return (
        <div className="registerCard">
            <h1>Register</h1>
            <p>Create your Wanteed account!</p>
            <div className="registerForm">
                <form onSubmit={handleRegister}>
                    <div className="inputRow">
                        <label htmlFor="first_name">First Name</label>
                        <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            className="inputGroup"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        /><br />
                    </div>
                    <div className="inputRow">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            className="inputGroup"
                            value={firstName}
                            onChange={(e) => setLastName(e.target.value)}
                        /><br />
                    </div>
                    <div className="inputRow">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="inputGroup"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        /><br />
                    </div>
                    <div className="inputRow">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            className="inputGroup"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        /><br />
                    </div>
                    <div className="inputRow">
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            className="inputGroup"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        /><br />
                    </div>
                    <button type="submit">Register</button>
                </form>
            </div>
            <p>Already have an account? <a href="/login">Log in</a></p>
            {notification && <Notification type={notification.type} message={notification.message} />}
        </div>
    );
}

export default Register;
