// src/Jobs.js
import React, { useState, useEffect } from "react";
import JobCard from "../components/JobCard";
import "../styles/Jobs.css";

function Jobs() {

    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch job IDs from /getjobs endpoint
                const response = await fetch('https://api.wanteed-job.com/getjobs?from=0&to=10', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                const data = await response.json();

                console.log('Raw data:', data); // Log the raw data received from the API

                // Assuming data is an array of job IDs, limit to 10 jobs
                const limitedJobIDs = data.slice(0, 10);
                console.log('Job IDs:', limitedJobIDs); // Log the limited job IDs

                // Fetch job details for each job ID
                const jobs = [];
                for (const jobID of limitedJobIDs) {
                    console.log('Fetching job:', jobID.job_id);
                    let realID = jobID.job_id;
                    const jobResponse = await fetch('https://api.wanteed-job.com/getjob?jobID=' + realID, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    let jobData = await jobResponse.json();
                    console.log(jobData);
                    console.log('Job data:', jobData[0]); // Log the fetched job details
                    jobData = jobData[0];
                    //jobData.description = jobData.description.substring(0, 1100) + "...";
                    jobs.push(jobData);
                }

                console.log('Jobs:', jobs); // Log the fetched job details

                // Set the fetched jobs to state
                setJobs(jobs);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching jobs:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="jobs-container">
            <h1>Offres disponibles</h1>
            <div className="filter-bar">
                <input type="text" placeholder="Rechercher" />
                <select>
                    <option value="all">Tous les types</option>
                    <option value="full-time">Plein temps</option>
                    <option value="part-time">Temps partiel</option>
                    <option value="internship">Stage</option>
                </select>
                <select>
                    <option value="all">Toutes les localisations</option>
                    <option value="paris">Paris</option>
                    <option value="lyon">Lyon</option>
                    <option value="marseille">Marseille</option>
                </select>
            </div>
            <div className="job-card-list">
                {jobs.map((job) => (
                    <JobCard
                        key={job.job_id}
                        id={job.job_id}
                        title={job.title}
                        company={job.company}
                        location={job.location}
                        type={job.type}
                        link={job.link}
                        description={job.description}
                    />
                ))}
            </div>
        </div>
    );
}

export default Jobs;