import React, { useState, useEffect, useCallback } from 'react';
import { getSessionToken } from '../utils/Auth';
import Button from './Button';
import '../styles/Candidats.css';

// Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Target icon
import { faBullseye } from '@fortawesome/free-solid-svg-icons';

// Wanteed logo to be displayed for candidates that come from Wanteed
//import wanteedLogo from '../assets/logo.png';


const Candidats = (jobData) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortCriteria, setSortCriteria] = useState('pertinence');
    const [sortOrder, setSortOrder] = useState('ascending');
    const sessionToken = getSessionToken();
    const [isLoading, setIsLoading] = useState(true);
    const [candidates, setCandidates] = useState([]);
    const [filteredCandidates, setFilteredCandidates] = useState([]);

    const job_id = jobData.jobData.job_id;
    const company_id = jobData.jobData.company_id;

    useEffect(() => {
        const fetchAndSetRecommendedCandidates = async () => {
            const response = await fetch(`https://api.wanteed-job.com/getrecommendatedcandidate?job_id=${job_id}&company_id=${company_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'sessionkey': sessionToken
                }
            });
            const candidates = await response.json();
            setCandidates(candidates);
            setFilteredCandidates(candidates);
            setIsLoading(false); 
        }
        fetchAndSetRecommendedCandidates();
    }, [job_id, company_id, sessionToken]);

// Function to handle filtering and sorting
const filterAndSortCandidates = useCallback(() => {
    // Filter based on search term
    let localCandidates = candidates;
    if (searchTerm) {
        localCandidates = candidates.filter(candidate =>
            candidate.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            candidate.last_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }
    else if (searchTerm === '') {
        localCandidates = candidates;
    }

    // Sort candidates based on selected criteria and order
    localCandidates.sort((a, b) => {
        const diff = a[sortCriteria] - b[sortCriteria];
        return sortOrder === 'ascending' ? -diff : diff;
    });

    setFilteredCandidates(localCandidates);
}, [searchTerm, sortCriteria, sortOrder, candidates]);

// Handle changes in search input
const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
};

// Handle changes in sort criteria
const handleSortCriteriaChange = (e) => {
    setSortCriteria(e.target.value);
};

// Handle changes in sort order
const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
};

// Trigger filtering and sorting when search term, sort criteria, or sort order changes
useEffect(() => {
    filterAndSortCandidates();
}, [searchTerm, sortCriteria, sortOrder, filterAndSortCandidates]);

return (
    isLoading ? <div>Loading...</div> :
    <div>
        <div className="filter-bar">
            <input
                type="text"
                placeholder="Rechercher un candidat..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
        </div>
        <div className="filter-bar">
            <select className="filter-select" value={sortCriteria} onChange={handleSortCriteriaChange}>
                <option value="pertinence">Pertinence</option>
                <option value="softSkills">Soft skills</option>
                <option value="hardSkills">Compétences techniques</option>
                <option value="cultureFit">Culture fit</option>
                <option value="teamFit">Team fit</option>
            </select>
            <select className="filter-select" value={sortOrder} onChange={handleSortOrderChange}>
                <option value="ascending">Ascendant</option>
                <option value="descending">Descendant</option>
            </select>
        </div>
        <div className="candidate-grid">
            {filteredCandidates.map(candidate => (
                <div key={candidate.user_id} className="candidate-card">
                    <div className="candidate-source">
                        <FontAwesomeIcon icon={faBullseye} />
                    </div>
                    <img src={`https://i.pravatar.cc/100?img=${candidate.first_name}`} alt={`${candidate.name}`} className="candidate-picture" />
                    <h3 className="candidate-name">{candidate.first_name + " " + candidate.last_name}</h3>
                    <div className="relevance-score">
                        <div className="candidate-grades">
                            <p>Soft skills : {candidate.softskill.toPrecision(3)}%</p>
                            <p>Hard skills : {candidate.hardskill.toPrecision(3)}%</p>
                            <p>Culture fit : {candidate.culture.toPrecision(3)}%</p>
                            <p>Team fit : {candidate.mbti.toPrecision(3)}%</p>
                        </div>
                        <div className="bubble">
                            {candidate.global.toPrecision(3)}%
                        </div>
                    </div>
                    <Button className="view-candidate-btn" variant='secondary' text={"Voir le candidat"} link={`/candidatepersona/${candidate.user_id}`} />
                </div>
            ))}
        </div>
    </div>
);
};

export default Candidats;
