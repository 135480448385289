import React, { useState, useEffect } from 'react';
import { getSessionToken } from '../utils/Auth';
import Notification from "../components/Notification";
import '../styles/EditProfile.css';

const EditProfile = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    civility: '',
    country: '',
    phone: '',
    email: '',
    city: '',
    newsletter: false,
    birth_date: '',
    address: ''
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Fetch user data on component mount
  useEffect(() => {
    fetch('https://api.wanteed-job.com/getUser', {
      method: 'GET',
      headers: { 'sessionKey': getSessionToken() } // Assuming sessionKey is stored and accessible
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to load user data.');
      }
      return response.json();
    })
    .then(data => {
      setFormData(data);
      setLoading(false);
    })
    .catch(() => {
      setError('Failed to load user data.');
      setLoading(false);
    });
  }, []);

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    fetch('https://api.wanteed-job.com/updateuser', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'sessionKey': getSessionToken()
      },
      body: JSON.stringify(formData)
    })
    .then(() => {
      setSuccess('Profile updated successfully.');
    })
  };

  // Handle form field changes
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <h1>Edit Profile</h1>
      <form onSubmit={handleSubmit}>
        <label>
          First Name:
          <input
            type="text"
            name="first_name"
            value={formData.first_name || ''}
            onChange={handleChange}
          />
        </label>
        <label>
          Last Name:
          <input
            type="text"
            name="last_name"
            value={formData.last_name || ''}
            onChange={handleChange}
          />
        </label>
        <label>
          Civility:
          <input
            type="text"
            name="civility"
            value={formData.civility || ''}
            onChange={handleChange}
          />
        </label>
        <label>
          Country:
          <input
            type="text"
            name="country"
            value={formData.country || ''}
            onChange={handleChange}
          />
        </label>
        <label>
          Phone:
          <input
            type="text"
            name="phone"
            value={formData.phone || ''}
            onChange={handleChange}
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email || ''}
            onChange={handleChange}
          />
        </label>
        <label>
          City:
          <input
            type="text"
            name="city"
            value={formData.city || ''}
            onChange={handleChange}
          />
        </label>
        <label>
          Newsletter:
          <input
            type="checkbox"
            name="newsletter"
            checked={formData.newsletter || ''}
            onChange={handleChange}
          />
        </label>
        <label>
          Birth Date:
          <input
            type="date"
            name="birth_date"
            value={formData.birth_date || ''}
            onChange={handleChange}
          />
        </label>
        <label>
          Address:
          <input
            type="text"
            name="address"
            value={formData.address || ''}
            onChange={handleChange}
          />
        </label>

        <button type="submit">Update Profile</button>
      </form>

      {success && <Notification type="success" message={success} />}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default EditProfile;
