// src/HomePage.js
import React from 'react';
import '../styles/HomePage.css';
import Hero from '../components/Hero';
import Footer from '../components/Footer';

function HomePage() {
  return (
    <div className='home-page'>
      <Hero />
      <Footer />
    </div >
  );
}

export default HomePage;
