// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getSessionToken, removeSessionToken } from '../utils/Auth';

const ProtectedRoute = ({ children, redirectTo = "/login" }) => {
    const token = getSessionToken();
    console.log("Verifying token {token}");
    console.log(token);

    if (!token) {
        return <Navigate to={redirectTo} />;
    }

    // Call the API to verify the token
    fetch('https://api.wanteed-job.com/checksession', {
        method: 'GET',
        headers: {
            'sessionkey': `${token}`
        }
    })
        .then(response => {
            if (response.status === 401) {
                console.log("Token is invalid");
                removeSessionToken();
                return <Navigate to={redirectTo} />;
            }
        })

    return children;
};

export default ProtectedRoute;