import React from 'react';
import '../styles/ToggleButton.css';

const ToggleButton = ({ view, setView }) => {
    return (
        <div className="toggle-button">
            <button
                className={view === 'stats' ? 'active' : ''}
                onClick={() => setView('stats')}
            >
                Stats
            </button>
            <button
                className={view === 'candidates' ? 'active' : ''}
                onClick={() => setView('candidates')}
            >
                Candidats
            </button>
        </div>
    );
};

export default ToggleButton;
