import React, { useState, useEffect } from 'react';
import '../styles/List.css';

const HardSkillList = () => {
    const [hardSkills, setHardSkills] = useState([]);

    useEffect(() => {
        fetch('https://api.wanteed-job.com/gethardskills',
            {
                method: 'GET',
                headers: {
                    'sessionkey': localStorage.getItem('token')
                }
            }
        )
            .then(response => response.json())
            .then(data => setHardSkills(data))
            .catch(error => console.error('Error fetching hard skills:', error));
    }, []);

    const handleDelete = async (hardskill) => {
        try {
            const response = await fetch('https://api.wanteed-job.com/removehardskill', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'sessionkey': localStorage.getItem('token')
                },
                body: JSON.stringify(hardskill)
            });
            if (response.ok) {
                setHardSkills(prevSkills => prevSkills.filter(skill => skill.hardskill !== hardskill.hardskill));
            } else {
                console.error('Failed to delete hard skill');
            }
        } catch (error) {
            console.error('Error deleting hard skill:', error);
        }
    };

    return (
        <div className="list">
            {hardSkills.map(skill => (
                <div key={skill.hardskill} className="list-item">
                    <div className="item-details">
                        <h3>{skill.hardskill}</h3>
                        <p>Type: {skill.type}</p>
                    </div>
                    <button className="delete-button" onClick={() => handleDelete(skill)}>Delete</button>
                </div>
            ))}
        </div>
    );
};

export default HardSkillList;
