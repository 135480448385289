import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { AuthContext } from './AuthContext';
import { getSessionToken, removeSessionToken } from '../utils/Auth';
import '../styles/Navbar.css';
import logo from "../assets/logo.png";

function Navbar() {
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // On page load, check if the user is logged in
    useEffect(() => {
        getSessionToken() ? setIsLoggedIn(true) : setIsLoggedIn(false)
    });

    const handleLogout = () => {
        removeSessionToken();
        setIsLoggedIn(false);
        navigate('/login');
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="navbar">
            <img src={logo} alt="Logo" className="logo" onClick={() => navigate('/')} />
            <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div className="burger"></div>
            </div>
            <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
                <li> <a href="/jobs" className="navlink">Explorer les offres</a></li>
                <li> <a href="/admin" className="navlink">Recruter sur Wanteed</a></li>
                {isLoggedIn && <li><Button text="Mon profil" link="/persona" variant="secondary" /></li>}
                {isLoggedIn ? (
                    <li><Button text="Logout" onClick={handleLogout}>Logout</Button></li>
                ) : (
                    <li><Button text="Login" link="/login" variant='inverted' /></li>
                )}
            </ul>
        </nav>
    );
}

export default Navbar;
