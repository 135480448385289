import React, { useState, useEffect } from 'react';
import { Radar, Bar } from 'react-chartjs-2';
import { Chart, RadialLinearScale, PointElement, LineElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { getSessionToken } from '../utils/Auth';
import '../styles/PersonaPage.css';

Chart.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
);

const CandidatePersonaPage = () => {
    const [personaData, setPersonaData] = useState([]);
    const [behaviourData, setBehaviourData] = useState([]);
    const [relationalData, setRelationalData] = useState([]);
    const [cognitiveData, setCognitiveData] = useState([]);
    const [cultureData, setCultureData] = useState([]);
    const [mbtiData, setMbtiData] = useState([]);
    const sessionKey = getSessionToken();

    // Get the candidateID from the URL (e.g. /candidatepersona/123)
    const candidateID = window.location.pathname.split('/').pop();
    console.log("candidate page", candidateID);

    useEffect(() => {
        console.log("useEffect called");
        if (!sessionKey || !candidateID) {
            console.error("Missing sessionKey or candidateID");
            return;
        }

        fetch(`https://api.wanteed-job.com/getcandidatepersonna?candidateID=${candidateID}`, {
            headers: {
                'Content-Type': 'application/json',
                'sessionkey': sessionKey
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                // Filter for soft skills only
                const softSkills = data.filter(trait => trait.type === "Softskill");
                setPersonaData(softSkills);

                // Filter for behaviour traits only, anglais francais
                const behaviourTraits = data.filter(trait => trait.type === "Behavior" || trait.type === "Comportement");
                setBehaviourData(behaviourTraits);

                // Filter for relational traits only
                const relationalTraits = data.filter(trait => trait.type === "Relational" || trait.type === "Relationnel");
                setRelationalData(relationalTraits);

                // Filter for cognitive traits only
                const cognitiveTraits = data.filter(trait => trait.type === "Cognitive" || trait.type === "Cognitif");
                setCognitiveData(cognitiveTraits);

                // Filter for MBTI traits only
                const mbtiTraits = data.filter(trait => trait.type === "MBTI");
                setMbtiData(mbtiTraits);

                // Filter for culture traits only
                const cultureTraits = data.filter(trait => trait.type === "Culture");
                console.log(cultureTraits);
                setCultureData(cultureTraits);
            })
            .catch(error => console.error('Error fetching persona data:', error));
    }, [sessionKey, candidateID]);

    const radarDataPersona = {
        labels: personaData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Persona Traits',
                data: personaData.map(trait => trait.value),
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: 'rgba(75,192,192,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarDataBehaviour = {
        labels: behaviourData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Behaviour Traits',
                data: behaviourData.map(trait => trait.value),
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                pointBackgroundColor: 'rgba(255,99,132,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarDataRelational = {
        labels: relationalData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Relational Traits',
                data: relationalData.map(trait => trait.value),
                backgroundColor: 'rgba(255,206,86,0.2)',
                borderColor: 'rgba(255,206,86,1)',
                pointBackgroundColor: 'rgba(255,206,86,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarDataCognitive = {
        labels: cognitiveData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Cognitive Traits',
                data: cognitiveData.map(trait => trait.value),
                backgroundColor: 'rgba(54,162,235,0.2)',
                borderColor: 'rgba(54,162,235,1)',
                pointBackgroundColor: 'rgba(54,162,235,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarDataCulture = {
        labels: cultureData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Culture Traits',
                data: cultureData.map(trait => trait.value),
                backgroundColor: 'rgba(153,102,255,0.2)',
                borderColor: 'rgba(153,102,255,1)',
                pointBackgroundColor: 'rgba(153,102,255,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    // Prepare MBTI data for bidirectional bar graph
    let mbtiTraitPairs = [
        ['Extroverts', 'Introverts'],
        ['Feelers', 'Thinkers'],
        ['Judgers', 'Perceivers'],
        ['Intuitive', 'Sensors'],
    ];

    // regarde si au moins un valeur dans mbtiTraitPairs est dans mbtiData sinon tu mets mbtiTraitPairs en francais
    let check = false;
    mbtiTraitPairs.forEach(pair => {
        if (mbtiData.find(trait => trait.trait_name === pair[0]) || mbtiData.find(trait => trait.trait_name === pair[1])) {
            check = true;
        }
    });

    if (!check) {
        mbtiTraitPairs = [
            ['Extravertis', 'Introvertis'],
            ['Affectifs', 'Pensants'],
            ['Juges', 'Perceptifs'],
            ['Intuitifs', 'Sensitifs'],
        ];
    }

    const mbtiValuesA = mbtiTraitPairs.map(pair => {
        const trait = mbtiData.find(trait => trait.trait_name === pair[0]);
        return trait ? trait.value : 0;
    });
    const mbtiValuesB = mbtiTraitPairs.map(pair => {
        const trait = mbtiData.find(trait => trait.trait_name === pair[1]);
        return trait ? trait.value : 0;
    });

    const bidirectionalData = {
        labels: mbtiTraitPairs.map(pair => `${pair[0]} / ${pair[1]}`),
        datasets: [
            {
                label: 'Left Traits',
                data: mbtiValuesA.map(value => -value),
                backgroundColor: 'rgba(75,192,192,0.5)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
            },
            {
                label: 'Right Traits',
                data: mbtiValuesB,
                backgroundColor: 'rgba(255,99,132,0.5)',
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
            }
        ],
    };

    const bidirectionalOptions = {
        indexAxis: 'y',
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return Math.abs(value);
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.raw !== null) {
                            label += Math.abs(context.raw);
                        }
                        return label;
                    },
                },
            },
        },
    };

    return (
        <div className="persona-page">
            <h1>Candidate persona</h1>
            <div className="chart-container">
                <div className="radar-chart">
                    <h2>Soft skills</h2>
                    <Radar data={radarDataPersona} />
                </div>
                <div className="radar-chart">
                    <h2>Behaviour Traits</h2>
                    <Radar data={radarDataBehaviour} />
                </div>
                <div className="radar-chart">
                    <h2>Relational Traits</h2>
                    <Radar data={radarDataRelational} />
                </div>
                <div className="radar-chart">
                    <h2>Cognitive Traits</h2>
                    <Radar data={radarDataCognitive} />
                </div>
                <div className="radar-chart">
                    <h2>Culture Traits</h2>
                    <Radar data={radarDataCulture} />
                </div>
                <div className="bar-chart">
                    <h2>Personality</h2>
                    <Bar data={bidirectionalData} options={bidirectionalOptions} />
                </div>
            </div>
        </div>
    );
};

export default CandidatePersonaPage;