import React from 'react';
import '../styles/EnterpriseHome.css';

const EnterpriseHome = () => {
  return (
    <div className="enterprise-home">
      <section className="intro-banner">
        <h1>Transformez votre recrutement avec notre IA de présélection</h1>
        <p>Accélérez votre processus de recrutement en identifiant les meilleurs talents grâce à une analyse approfondie de leurs compétences, de leur personnalité et de leur compatibilité culturelle.</p>
        <button className="cta-button">Demandez une démo</button>
      </section>

      <section className="presentation">
        <h2>Une IA puissante pour un recrutement efficace</h2>
        <p>Notre IA analyse chaque candidat à travers plusieurs critères essentiels, vous permettant de concentrer votre temps sur les meilleurs profils. Nos algorithmes sophistiqués prennent en compte les compétences techniques, les soft skills, et l'adéquation culturelle pour vous offrir une présélection précise et personnalisée.</p>
        {/* Visualisation avec une image ou une animation */}
      </section>

      <section className="features">
        <h2>Fonctionnalités clés</h2>
        <ul>
          <li>Analyse des compétences techniques</li>
          <li>Évaluation des soft skills</li>
          <li>Compatibilité culturelle</li>
          <li>Rapports personnalisés</li>
        </ul>
      </section>

      <section className="testimonials">
        <h2>Ils nous font confiance</h2>
        {/* Slider ou grille de témoignages */}
      </section>

      <section className="cta-section">
        <h2>Prêt à révolutionner votre recrutement ?</h2>
        <button className="cta-button">Demandez une démo</button>
        <button className="cta-button secondary">Contactez-nous pour plus d'informations</button>
      </section>
    </div>
  );
};

export default EnterpriseHome;
