import React, { useEffect, useState } from 'react';
import '../styles/Notification.css';

const Notification = ({ message, type, onClose }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        // Automatically hide the notification after 3 seconds
        const timer = setTimeout(() => {
            setVisible(false); // Trigger fade-out
        }, 3000);

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, []);

    useEffect(() => {
        if (!visible) {
            // Wait for the fade-out transition to complete before calling onClose
            const timeoutId = setTimeout(onClose, 300); // This duration should match the CSS transition duration
            return () => clearTimeout(timeoutId); // Cleanup the timeout on unmount
        }
    }, [visible, onClose]);

    // If the notification is not visible, return null (don't render anything)
    if (!visible) return null;

    return (
        <div className={`notification ${type} ${visible ? 'fade-in' : 'fade-out'}`}>
            <p>{message}</p>
            <button className="close-btn" onClick={() => setVisible(false)}>x</button>
        </div>
    );
};

export default Notification;
