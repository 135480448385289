import React, { useState, useEffect } from 'react';
import { getSessionToken } from '../utils/Auth';
import '../styles/JobModal.css';

const JobModal = ({ selectedCompany, closeModal, fetchJobs, edit = false, data = {
    job_id: '',
    title: '',
    domain_id: '',
    type: '',
    salary: '',
    city: '',
    country: '',
    description: '',
    redirection: '',
} }) => {
    const [formData, setFormData] = useState(data);
    const [categories, setCategories] = useState([]);
    const [domains, setDomains] = useState({});
    const token = getSessionToken();
    const [questions, setQuestions] = useState([
        { id: 1, text: "Comment votre dernier manager vous décrirait-il ?" },
    ]);

    const addQuestion = () => {
        setQuestions([
            ...questions,
            { id: questions.length + 1, text: "" } // Add a new empty question
        ]);
    };

    const removeQuestion = (id) => {
        setQuestions(questions.filter(question => question.id !== id));
    };

    const handleQuestionChange = (id, newText) => {
        setQuestions(questions.map(question =>
            question.id === id ? { ...question, text: newText } : question
        ));
    };

    useEffect(() => {
        const fetchDomains = async () => {
            try {
                const response = await fetch('https://api.wanteed-job.com/getdomains', {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                const data = await response.json();
                setCategories(Object.keys(data));
                setDomains(data);
            }
            catch (error) {
                console.error('Error fetching domains:', error);
            }
        };

        fetchDomains();
    }, [token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(edit ? `https://api.wanteed-job.com/editjobcompany` : 'https://api.wanteed-job.com/addjobcompany', {
                method: edit ? 'PATCH' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'sessionkey': token,
                },
                body: JSON.stringify({
                    company_id: selectedCompany.company_id,
                    ...formData,
                }),
            });
            console.log(response);
            closeModal();
            fetchJobs(selectedCompany.company_id);
            //TODO replace redirectionUrl with job's redirection URL
        } catch (error) {
            console.error('Error adding job:', error);
        }
    };



    return (
        <form onSubmit={handleSubmit} className="modal-form">

            <div>
                <label>Job Title</label>
                <input type="text" name="title" value={formData.title} onChange={handleInputChange} required />
            </div>

            <div>
                <label>Domain</label>
                <select name="domain_id" value={formData.domain_id} onChange={handleInputChange} required>
                    <option value="">Select Domain</option>
                    {categories.map((category) => (
                        <optgroup key={category} label={category}>
                            {domains[category].map((domain) => (
                                <option key={domain.id} value={domain.id}>
                                    {domain.profession}
                                </option>
                            ))}
                        </optgroup>
                    ))}
                </select>
            </div>

            <div>
                <label>Type</label>
                <input type="text" name="type" value={formData.type} onChange={handleInputChange} required />
            </div>

            <div>
                <label>Salary</label>
                <input type="text" name="salary" value={formData.salary} onChange={handleInputChange} required />
            </div>

            <div>
                <label>City</label>
                <input type="text" name="city" value={formData.city} onChange={handleInputChange} required />
            </div>

            <div>
                <label>Country</label>
                <input type="text" name="country" value={formData.country} onChange={handleInputChange} required />
            </div>

            <div className="full-width">
                <label>Description</label>
                <textarea name="description" value={formData.description} onChange={handleInputChange} required></textarea>
            </div>


            <div className='custom-test'>
                <h4>Custom test questions</h4>
                {questions.map((question, index) => (
                    <div key={question.id} className='custom-test-question'>
                        <input
                            type="text"
                            name={`question-${question.id}`}
                            placeholder={`Question ${index + 1}`}
                            value={question.text}
                            onChange={(e) => handleQuestionChange(question.id, e.target.value)}
                        />
                        <button
                            type="button"
                            className='custom-test-button-remove'
                            onClick={() => removeQuestion(question.id)}
                        >
                            -
                        </button>
                    </div>
                ))}
                <button
                    type="button"
                    className='custom-test-button'
                    onClick={addQuestion}
                >
                    +
                </button>
            </div>

            <button type="submit">Save</button>
            <button type="button" onClick={closeModal} className='cancel'>Cancel</button>
        </form>
    );
};

export default JobModal;
