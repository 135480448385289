// src/pages/HardSkillsPage.js
import React from 'react';
import HardSkillForm from '../components/HardSkillForm';
import HardSkillList from '../components/HardSkillList';
import ExperienceForm from '../components/ExperienceForm';
import ExperienceList from '../components/ExperienceList';
import '../styles/HardSkillsPage.css';

const HardSkillsPage = () => {
    return (
        <div className="hard-skills-page">
            <div className="container">
                <h1>Manage Your Hard Skills and Experiences</h1>
                <div className="section">
                    <h2>Add New Hard Skill</h2>
                    <HardSkillForm />
                </div>
                <div className="section">
                    <h2>Your Hard Skills</h2>
                    <HardSkillList />
                </div>
                <div className="section">
                    <h2>Add New Experience</h2>
                    <ExperienceForm />
                </div>
                <div className="section">
                    <h2>Your Experiences</h2>
                    <ExperienceList />
                </div>
            </div>
        </div>
    );
};

export default HardSkillsPage;
