// src/utils/Auth.js
import Cookies from 'js-cookie';

// Set the session token in a cookie
export const setSessionToken = (token) => {
    Cookies.set('sessionToken', token, {
        expires: 7, // Expires in 7 days
        secure: true, // Use only over HTTPS
        sameSite: 'Strict', // Protect against CSRF attacks
        path: '/', // Available across the whole site
    });
};

// Get the session token from the cookie
export const getSessionToken = () => {
    return Cookies.get('sessionToken');
};

// Remove the session token from the cookie
export const removeSessionToken = () => {
    Cookies.remove('sessionToken', { path: '/' });
};
