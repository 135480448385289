import React, { useState, useEffect } from 'react';
import '../styles/List.css';

const ExperienceList = () => {
    const [experiences, setExperiences] = useState([]);

    useEffect(() => {
        fetch('https://api.wanteed-job.com/getexperiences',
            {
                method: 'GET',
                headers: {
                    'sessionkey': localStorage.getItem('token')
                }
            })
            .then(response => response.json())
            .then(data => setExperiences(data))
            .catch(error => console.error('Error fetching experiences:', error));
    }, []);

    const handleDelete = async (experienceId) => {
        try {
            const response = await fetch('https://api.wanteed-job.com/removeexperience', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'sessionkey': localStorage.getItem('token')
                },
                body: JSON.stringify({ experience_id: experienceId })
            });
            if (response.ok) {
                setExperiences(prevExperiences => prevExperiences.filter(exp => exp.experience_id !== experienceId));
            } else {
                console.error('Failed to delete experience');
            }
        } catch (error) {
            console.error('Error deleting experience:', error);
        }
    };

    return (
        <div className="list">
            {experiences.map(experience => (
                <div key={experience.experience_id} className="list-item">
                    <div className="item-details">
                        <h3>{experience.profession} at {experience.company}</h3>
                        <p>{experience.domain} - {experience.duration} years</p>
                        <p>{experience.description}</p>
                    </div>
                    <button className="delete-button" onClick={() => handleDelete(experience.experience_id)}>Delete</button>
                </div>
            ))}
        </div>
    );
};

export default ExperienceList;
