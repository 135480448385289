import React, { useState, useEffect } from 'react';
import { getSessionToken } from '../utils/Auth';
import { useNavigate } from 'react-router-dom';
import '../styles/SoftSkillTest.css';

const SoftSkillTest = () => {
    const [question, setQuestion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [sliderValue, setSliderValue] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        fetchQuestion();
    }, []);

    const fetchQuestion = async () => {
        const token = getSessionToken();
        console.log(token);
        try {
            const response = await fetch('https://api.wanteed-job.com/getquestionsoftskill', {
                method: 'GET',
                headers: {
                    'sessionkey': `${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setQuestion(data);
            setLoading(false);
            setSliderValue(0); // Reset slider value for each new question
        } catch (error) {
            setError('Failed to load question. Please try again. (You may have answered all questions)');
            setLoading(false);
        }
    };

    const handleAnswer = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://api.wanteed-job.com/answerquestionsoftskill', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'sessionkey': token,
                },
                body: JSON.stringify({
                    questionID: question.question_id,
                    value: sliderValue,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            fetchQuestion(); // Fetch the next question
        } catch (error) {
            setError('Failed to submit answer. Please try again.');
        }
    };

    const handleSliderChange = (event) => {
        setSliderValue(parseInt(event.target.value));
    };

    return (
        <div className="softskill-test">
            <div className="test-switcher">
            <button className="test-button" onClick={() => navigate('/culturefit')}>Culture Fit</button>
                <button className="test-button-active" onClick={() => navigate('/softskilltest')}>Soft skills</button>
                <button className="test-button" onClick={() => navigate('/hardskills')}>Hard skills</button>
                <button className="test-button" onClick={() => navigate('/mbti')}>Team fit</button>
            </div>
            <h1>Soft Skill Test</h1>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="error">{error}</p>
            ) : (
                question && (
                    <div className="question-container">
                        <div className="question-options">
                            <p className="question">{question.question_a}</p>
                            <p className="question">{question.question_b}</p>
                        </div>
                        <input
                            type="range"
                            min="-3"
                            max="3"
                            value={sliderValue}
                            onChange={handleSliderChange}
                            className="slider"
                        />
                        <div className="scale-labels">
                            <span>-3</span>
                            <span>-2</span>
                            <span>-1</span>
                            <span>0</span>
                            <span>1</span>
                            <span>2</span>
                            <span>3</span>
                        </div>
                        <button onClick={handleAnswer} className="submit-button">
                            Submit
                        </button>
                    </div>
                )
            )}
        </div>
    );
};

export default SoftSkillTest;
